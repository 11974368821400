import React from 'react';
import './application.scss';
import Ogle from '../theme/ogle';
import {Route, Routes} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import useQuery from '../../utilities/use-query';
import {IntlProvider} from 'react-intl';
import en from '../../../compiled-lang/en.json';
import uk from '../../../compiled-lang/uk.json';
import {ErrorBoundary} from 'react-error-boundary';
import FallbackComponent from '../fallback-component';
import Home from '../home';
import * as iframe from '../../utilities/iframe';

const locales = {en, uk};

const IntlProviderUrlConnected = React.memo(({children}) => {
  const {locale = 'uk'} = useQuery();
  return (
      <IntlProvider locale={locale} defaultLocale='en' messages={locales[locale]}>
          {children}
      </IntlProvider>
  );
});

const Application = React.memo(() => {
  return (
    <>
    <Helmet>
        <title>Welcome to Service</title>
        <meta name='description' content='Web site of your dream'/>
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
    </Helmet>
    <Ogle>
      <Routes>
        <Route path='/' element={<Home/>}/>
      </Routes>
    </Ogle>
    </>
  );
});


iframe.apply();

export default React.memo(() => (
    <IntlProviderUrlConnected>
      <ErrorBoundary {...{FallbackComponent}}>
        <Application/>
      </ErrorBoundary>
    </IntlProviderUrlConnected>
));