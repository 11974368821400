import React from 'react';
import Ticket01Svg from '../assets/icons0/ticket-01.svg';
import MinusCircleSvg from '../assets/icons/cart-minus-circle.svg';
import PlusCircleSvg from '../assets/icons/cart-add-circle.svg';
import SuspenseImageEx from './suspense-image-ex';
import Skeleton from './skeleton/skeleton';
import FallbackComponent from './fallback-component';
import useQuery from '../utilities/use-query';
import {ErrorBoundary} from 'react-error-boundary';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import i18n from '../utilities/i18n';
import {isSet, length} from '../utilities/utility';
import {useCartDispatch, useCartState} from './cart';
import classNames from 'classnames';
import WinChan from 'winchan';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore, useAuthorizationState} from './authorization';
import {graphqlWhoamiNextQuery} from './graphql';
import searchFilter from '../utilities/search-filter';
import {useLocation} from 'react-router-dom';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_INTEGRATION = process.env.RAZZLE_APP_INTEGRATION;
// const RAZZLE_APP_SIGNOUT = process.env.RAZZLE_APP_SIGNOUT;

const split = (value) => (typeof(value) === 'number' ? value.toString() : value).split('.');

const Footer = React.memo(() => {
  return (
    <>
    <div className='border-bottom-1px-solid border-color-gray-200 height-0dot0625rem'>&nbsp;</div>
    <div className='padding-top-1rem padding-bottom-1dot5rem display-flex justify-content-space-between align-items-center'>
      <div className='display-flex align-items-center'>
        <div className='text-sm color-gray-500 padding-right-0dot5rem'>
          Powered by
        </div>
        <SuspenseImageEx
            style={{
              width: '2.69813rem',
              height: '1.0625rem'
            }}
            className='display-block'
            src='/assets/logo@3x.png'
        />
      </div>
      <div>
        <SuspenseImageEx
            style={{
              width: '10rem',
              height: '1.5rem'
            }}
            className='display-block'
            src='/assets/frame@3x.png'
        />
      </div>
    </div>
    </>
  );
});

const AmountImpl = React.memo(({cartState, flag, state}) => {
  const location = useLocation();
  const request = React.useMemo(
    () => ({picks: cartState.map((e) => ({endpoint: e.endpointId, service: e.serviceId, quantity: e.quantity}))}),
    [cartState]);
  const {landImpulse} = useLazyLoadQuery(
      graphql`
          query homeAmountImplComponentsLandImpulseQuery($request: LandRequestInput) {
              landImpulse(request: $request) {
                  lands {
                      pickExs {
                          index
                      }
                  }
                  solves {
                      amount
                      serviceFeeAmount
                      basis
                  }
              }
          }
      `,
      {request}
  );
  return (
    <div className='padding-top-2dot5rem display-flex justify-content-flex-end'>  
      <div 
        style={{boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)'}}
        className='width-100percent mw768-width-50percent padding-left-1dot25rem padding-top-1dot25rem padding-right-1dot125rem padding-bottom-1dot5rem border-radius-1rem border-1px-solid border-color-gray-100'
      >
        {(isSet(landImpulse.solves[0]?.serviceFeeAmount) && parseFloat(landImpulse.solves[0].serviceFeeAmount) !== 0) &&
        <>
        <div className='display-flex justify-content-space-between align-items-baseline'>
          <div className='text-sm color-gray-500'>
            Замовлення
          </div>
          <div className={classNames('text-md medium color-gray-500', {
            'opacity-0dot6': flag
          })}>
            {landImpulse.solves[0].basis} грн
          </div>
        </div>
        <div className='padding-top-0dot25rem display-flex justify-content-space-between align-items-baseline'>
          <div className='text-sm color-gray-500'>
            Сервісний збір
          </div>
          <div className={classNames('text-md medium color-gray-500', {
            'opacity-0dot6': flag
          })}>
            {landImpulse.solves[0].serviceFeeAmount} грн
          </div>
        </div>
        </>
        }
        <div className='padding-top-0dot75rem display-flex justify-content-space-between align-items-baseline'>
          <div className='text-sm color-gray-700'>
            До сплати
          </div>
          <div className={classNames('text-lg semibold color-gray-900', {
            'opacity-0dot6': flag
          })}>
            {split(landImpulse.solves[0]?.amount || '00.00')[0]}.
            <span className='text-sm bold'>{split(landImpulse.solves[0]?.amount || '00.00')[1]}</span>
            {' '}
            грн
          </div>
        </div>
        <div className='padding-top-0dot75rem'>
          <a href={`${RAZZLE_APP_INTEGRATION}${searchFilter(location.search, {
            type: 'payment-link',
            c: state.map((e) => ({e: e.endpointId, s: e.serviceId, q: e.quantity}))
          })}`} target='_blank'>
            <div 
              style={{
                  background: 'linear-gradient(88.92deg, #1570EF 0%, #2E90FA 100%)',
                  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
              }}
              className='border-radius-0dot5rem padding-top-0dot625rem padding-bottom-0dot625rem padding-left-1dot25rem padding-right-1dot25rem display-flex justify-content-center align-items-center'
            >
                <span className='text-md medium color-white'>Оформити</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
});

const Amount = React.memo(() => {
  const cartState = useCartState();
  const cartStateDeferred = React.useDeferredValue(cartState);
  return (
    <AmountImpl {...{
      cartState: cartStateDeferred,
      flag: cartState !== cartStateDeferred,
      state: cartState
    }}/>
  );
});

const Endpoint = React.memo(({endpoint, serviceById, state}) => {
  const cartDispatch = useCartDispatch();
  const {locale = 'uk'} = useQuery();
  const fEndpoint = useFragment(
    graphql`
        fragment homeEndpointComponents_endpoint on Endpoint {
          id
          name
          nameUk
          price
        }
    `,
    endpoint
  );
  const fServiceById = useFragment(
    graphql`
        fragment homeEndpointComponents_serviceById on Service {
            id
        }
    `,
    serviceById
  );
  return (
    <div className='padding-top-1dot5rem first-child:padding-top-0'>
      <div className='padding-1dot25rem border-radius-0dot75rem border-1px-solid border-color-gray-100'>
        <div className='text-lg medium color-gray-700'>
          {i18n(fEndpoint, 'name', locale)}
        </div>
        {/* <div className='padding-top-0dot5rem text-sm color-gray-500'>
          15 жовтня 2022 р.
        </div> */}
        <div className='padding-top-1rem display-flex align-items-center justify-content-space-between'>
          <div className='text-lg medium color-gray-700'>
            {fEndpoint.price} грн
          </div>
          {(state && state.quantity) ?
            <div className='padding-0dot5rem border-radius-0dot5rem border-1px-solid border-color-gray-100'>
              <div className='display-flex'>
                <MinusCircleSvg 
                  className='cursor-pointer display-block height-1dot5rem width-1dot5rem color-primary-600'
                  onClick={() => {
                    cartDispatch({
                      type: 'remove', 
                      payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id, quantity: 1}
                    });
                  }}
                />
                <div className='padding-left-0dot5rem padding-right-0dot5rem display-flex align-items-center'>
                  <div className='padding-left-0dot75rem padding-right-0dot75rem text-md medium color-gray-700'>
                    {state.quantity}
                  </div>
                </div>
                <PlusCircleSvg 
                  className='cursor-pointer display-block height-1dot5rem width-1dot5rem color-primary-600'
                  onClick={() => {
                    cartDispatch({
                      type: 'add', 
                      payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id, quantity: 1}
                    });
                  }}
                />
              </div>
            </div>
            :
            <div
              style={{boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)'}}
              className='cursor-pointer padding-left-1rem padding-right-1rem padding-top-0dot5rem padding-bottom-0dot5rem border-radius-0dot5rem border-1px-solid border-color-gray-300'
              onClick={() => {
                cartDispatch({
                  type: 'add', 
                  payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id, quantity: 1}
                });
              }}
            >
              <div className='display-flex justify-content-center align-items-center text-md medium color-primary-600'>
                Купити
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
});

const findByServiceIdAndEndpointId = (state, endpointId, serviceId) => state.find(e => e.endpointId === endpointId && e.serviceId === serviceId);

const Endpoints = React.memo(() => {
  const cartState = useCartState();
  const {serviceId} = useQuery();
  const {serviceById} = useLazyLoadQuery(
    graphql`
        query homeEndpointsComponentsServiceByIdQuery($id: String) {
            serviceById(id: $id) {
              id
              endpoints {
                  id
                  name
                  nameUk
                  price
                  ...homeEndpointComponents_endpoint
              }
              ...homeEndpointComponents_serviceById
            }
        }
    `,
    {id: serviceId}
  );
  return (
    <div className='padding-top-2rem'>
      {length(serviceById.endpoints) ? 
        <>
        {serviceById.endpoints.map((endpoint) => <Endpoint {...{key: endpoint.id, endpoint, serviceById, state: findByServiceIdAndEndpointId(cartState, endpoint.id, serviceById.id)}}/>)}
        <ErrorBoundary fallbackRender={({error}) => 
          <div className='padding-top-2dot5rem'>
            <FallbackComponent {...{error}}/>
          </div>
        }>
          <Amount/>
        </ErrorBoundary>
        </>
        :
        <div className='text-md color-gray-700'>Немає даних</div>
      }
    </div>
  );
});

// https://github.com/auth0/auth0.js/blob/master/src/helper/popup-handler.js
// https://gist.github.com/gauravtiwari/2ae9f44aee281c759fe5a66d5c2721a2
const popup = (url) => {
  const windowArea = {width: 768, height: 800};
  const outerWidth =
      typeof window.outerWidth !== 'undefined' ? window.outerWidth : window.document.body.clientWidth;
  const outerHeight =
      typeof window.outerHeight !== 'undefined' ? window.outerHeight : window.document.body.clientHeight;
  windowArea.left = (outerWidth - windowArea.width) / 2;
  windowArea.top = (outerHeight - windowArea.height) / 2;
  const windowOpts = `toolbar=0,scrollbars=1,status=1,resizable=1,location=1,menuBar=0,width=${windowArea.width},height=${windowArea.height},left=${windowArea.left},top=${windowArea.top}`;
  return new Promise((resolve, reject) => {
      const authWindow = WinChan.open(
          {url: url, relay_url: RAZZLE_APP_ACCOUNTS + '/relay.html', window_features: windowOpts, window_name: 'null0'},
          function(err, r) {
              if (err) { reject(err); return; }
              authWindow.close();
              resolve(r);
          }
      );
  });
};

const onSignIn = (locale, onSuccess, onError) => {
  popup(RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&continue' + (locale ? `&locale=${locale}` : '')).then(
      (rawToken) => onSuccess(JSON.parse(rawToken)),
      (error) => onError(error.toString())
  );
};

// const onSignOut = (locale, onSuccess, onError) => {
//   popup(
//       RAZZLE_APP_ACCOUNTS + '/' + RAZZLE_APP_SIGNOUT + '.html?cache=0&continue=' +
//       encodeURIComponent(RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&continue' + (locale ? `&locale=${locale}` : ''))
//   ).then(
//       (rawToken) => onSuccess(JSON.parse(rawToken)),
//       (error) => onError(error.toString())
//   );
// };

const User = React.memo(({userMenuRef, toggleUserMenu}) => {
  const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
  return (
    <div className='text-sm medium color-gray-600'>
      {whoamiNext.username || whoamiNext.email}
    </div>
  );
});

const Auth = React.memo(() => {
  const {locale = 'uk'} = useQuery();
  const authorizationDispatch = useAuthorizationDispatch();
  const authorizationStore = useAuthorizationStore();
  const authorizationState = useAuthorizationState();
  return (
    <div className='padding-top-5rem'>
      
        {authorizationState ?
          <>
          <div className='display-flex justify-content-flex-end'>
            <React.Suspense>
              <div className='padding-right-0dot5rem'>
                <User/>
              </div>
            </React.Suspense>
            <div
              className='cursor-pointer text-sm medium color-primary-600'
              onClick={() => {
                authorizationStore.remove(tokenType__);
                authorizationStore.remove(accessToken__);
                authorizationStore.remove(refreshToken__);
                authorizationDispatch();
                // onSignOut(
                //   locale,
                //   (e) => {
                //     authorizationStore.set(tokenType__, e[tokenType__]);
                //     authorizationStore.set(accessToken__, e[accessToken__]);
                //     authorizationStore.set(refreshToken__, e[refreshToken__]);
                //     authorizationDispatch();
                //   },
                //   () => {}
                // );
              }}
            >Вийти</div>
          </div>
          </>
          :
          <div className='display-flex justify-content-flex-end'>
            <div 
              className='cursor-pointer text-sm medium color-primary-600'
              onClick={() => onSignIn(
                locale, 
                (e) => {
                  authorizationStore.set(tokenType__, e[tokenType__]);
                  authorizationStore.set(accessToken__, e[accessToken__]);
                  authorizationStore.set(refreshToken__, e[refreshToken__]);
                  authorizationDispatch();
                }, 
                () => {}
              )}
            >Увійти</div>
          </div>
        }
    </div>
  );
});

export default React.memo(() => {
  return (
    <>
      <div className='padding-left-1dot25rem padding-right-1dot25rem'>
        <div className='padding-top-2rem'>
          <div className='display-flex align-items-center'>
            <Ticket01Svg className='display-block height-1dot5rem width-1dot5rem color-primary-600'/>
            <div className='padding-left-0dot75rem text-lg medium color-gray-700'>
              Квитки
            </div>
          </div>
          <div className='padding-top-1rem'>
            <div className='border-bottom-1px-solid border-color-gray-200 height-0dot0625rem'>&nbsp;</div>
          </div>
        </div>
        <React.Suspense fallback={
          <div className='padding-top-2rem padding-bottom-1dot5rem'>
            <Skeleton/>
          </div>
        }>
          <ErrorBoundary fallbackRender={({error}) => 
            <div className='padding-top-2rem'>
              <FallbackComponent {...{error}}/>
            </div>
          }>
            <Endpoints/>
          </ErrorBoundary>
          <Auth/>
          <Footer/>
        </React.Suspense>        
      </div>
    </>
  );
});

