let Channel;
let channel;

const isIframe = () => (typeof window !== 'undefined') && (window.self !== window.parent);

export const apply = () => {
    if (isIframe()) {
        Channel = require('jschannel');
        const applyHeight = () => {
            document.getElementsByTagName('body')[0].style.height = 'auto';
            const SYNC_HEIGHT_TIMEOUT = 400;
            let height = 0;
            const syncHeight = () => {
                // const height_ = document.getElementById('root').firstChild.offsetHeight;
                const height_ = document.getElementsByTagName('body')[0].offsetHeight;
                if (height !== height_) {
                    height = height_;
                    channel.notify({method: 'height', params: height});
                }
            };
            const syncHeightContinuous = () => {
                syncHeight();
                setTimeout(syncHeightContinuous, SYNC_HEIGHT_TIMEOUT);
            };
            syncHeightContinuous();
        };
        channel = Channel.build({window: window.parent, origin: '*'});
        applyHeight();
    }
};