import qs from 'qs';

const getKeys = (obj, parentKey = '') => {
    const keys = [];
    if (parentKey) {
        keys.push(parentKey);
    }
    for (const key in obj) {
        const currentKey = parentKey ? `${parentKey}[${key}]` : key;
        if (typeof obj[key] === 'object') {
            keys.push(...getKeys(obj[key], currentKey));
        } else {
            keys.push(currentKey);
        }
    }
    return keys;
};

const filterFunc = (keys, prefix, value) => {
    if (!keys.includes(prefix)) {
        return;
    }
    return value;
};

export default (search, inline = {}, release = true) => {
    const current = qs.parse(search, {ignoreQueryPrefix: true});
    const keys = [...new Set(
        ['locale', 'currency', 'apply']
            .concat(getKeys(inline).concat(''))
            .concat(release ? [] : Object.keys(current))
    )];
    const filter = (prefix, value) => filterFunc(keys, prefix, value);
    return qs.stringify({...current, ...inline}, {filter, addQueryPrefix: true});
};