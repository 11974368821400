import React from 'react';

export const CartStateContext = React.createContext([]);
export const CartDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add': {
            const entry = state.find(entry => 
                entry.endpointId === action.payload.endpointId 
                && entry.serviceId === action.payload.serviceId);
            return [
                ...(entry ? state.filter(e => e !== entry) : state),
                {...(entry
                    ? {...entry, ...action.payload, quantity: entry.quantity + action.payload.quantity}
                    : {quantity: 1, ...action.payload})}
            ];
        }
        case 'remove': {
            const entry = state.find(entry => 
                entry.endpointId === action.payload.endpointId 
                && entry.serviceId === action.payload.serviceId);
            return [
                ...(entry ? state.filter(e => e !== entry) : state),
                ...(entry 
                    ? (entry.quantity > action.payload.quantity 
                        ? [{...entry, ...action.payload, quantity: entry.quantity - action.payload.quantity}] 
                        : []) 
                    : []),
            ];
        }
        default: throw new Error();
    }
};

export const CartProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => []);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            return nextState;
        });
    }, []);
    return (
        <CartStateContext.Provider value={state}>
            <CartDispatchContext.Provider value={dispatch}>
                {children}
            </CartDispatchContext.Provider>
        </CartStateContext.Provider>
    );
});

export const useCartState = () => React.useContext(CartStateContext);
export const useCartDispatch = () => React.useContext(CartDispatchContext);