import 'isomorphic-fetch';
import Application from './components/application/application';
import {BrowserRouter} from 'react-router-dom';
import React from 'react';
import {hydrateRoot} from 'react-dom/client';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import createRelayEnvironment from './utilities/create-relay-environment';
import {AuthorizationProvider} from './components/authorization';
import {MoveProvider} from './components/move';
import {CartProvider} from './components/cart';
import {HelmetProvider} from 'react-helmet-async';

const store = {
  get: function(field) {return this[field];},
  set: function(field, value) {this[field] = value;},
  remove: function(field) {delete this[field];},
};

hydrateRoot(
  document.getElementById('root'),
  <RelayEnvironmentProvider 
    environment={createRelayEnvironment(
      () => ({'Content-Type': 'application/json'}), store)}
  >
    <BrowserRouter>
      <HelmetProvider>
        <AuthorizationProvider {...{store}}>
          <MoveProvider>
            <CartProvider>
              <Application/>
            </CartProvider>
          </MoveProvider>
        </AuthorizationProvider>
      </HelmetProvider>
    </BrowserRouter>
  </RelayEnvironmentProvider>
  // , { onRecoverableError: (e) => {} }
);

if (module.hot) {
  module.hot.accept();
}
